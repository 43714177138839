<template>
  <el-dialog
    :visible.sync="maximumQueryLimitShow"
    :modal-append-to-body="false"
    :before-close="handleClose"
    width="417px"
    custom-class="common-dialog"
    :close-on-click-modal="false"
  >
    <div class="title">
      <svg-icon icon-class="lead-limit-tip" class="lead-limit-tip" />
      <span>Lead Limit Reached</span>
    </div>
    <div class="content">
      <div>You've reached the limit for the number of leads on your current plan. </div>
      <div class="upgrade" @click="upgrade">Upgrade to Business to unlock 150 leads/month! or <span class="explore-all">Explore all plans</span></div>
    </div>
    <div class="btns-wrapper">
      <el-button @click="handleClose" class="btn-group-1">{{
        $t("Cancel")
      }}</el-button>
      <el-button type="primary" @click="upgrade" class="btn-group-2">Upgrade Now</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  props: ["dialogVisible"],
  computed: {
    ...mapState('common', ['maximumQueryLimitShow']),
  },
  data() {
    return {
    };
  },
  methods: {
    ...mapMutations('common', ['setMaximumQueryLimitShow']),
    upgrade() {
      this.setMaximumQueryLimitShow();
      this.$router.push('/settings/upgrade-plan')
    },
    handleClose() {
      this.setMaximumQueryLimitShow();
    },
  }
};
</script>

<style scoped lang="scss">
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 24px;
  color: $color-black-2;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.54px;
  .lead-limit-tip {
    width: 24px;
    height: 24px;
  }
}
.content {
  margin-bottom: 24px;
  color: $color-black-2;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.42px;
  .upgrade {
    margin-top: 16px;
    font-weight: 600;
    .explore-all {
      font-style: italic;
      color: $color-blue-6;
      font-weight: 400;
      cursor: pointer;
    }
  }
}
</style>