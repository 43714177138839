export const SEARCH_FILTER = 1;
export const SEARCH_SAVED_SEARCHES = 2;
export const SEARCH_SAVED_LIST = 3;


export const TYPE_SAVED_SEARCHES = 1;
export const TYPE_SAVED_LISTS = 2;

export const PEOPLE_TYPE = 1;
export const COMPANY_TYPE = 2;
export const INTENT_TYPE = 4;

export const TYPE_ADD = 1;
export const TYPE_EDIT = 2;

export const SAVED_LIST_SAVE = 1;
export const SAVED_LIST_UNSAVE = 2;

export const PAGE_SELECT_ALL = 1;
export const PAGE_SELECT_THIS_PAGE = 2;
export const PAGE_SELECT_THIS_CUSTOMIZE = 3;

export const CATEGORY_ALL = 'all';
export const CATEGORY_MY = 'private';
export const CATEGORY_TEAM = 'team';

export const categoryMap = new Map([
  [CATEGORY_ALL, null],
  [CATEGORY_MY, true],
  [CATEGORY_TEAM, false],
]);


export const PAGE_PEOPLE = 1;
export const PAGE_COMPANY = 2;
export const PAGE_INTENT_SAVED_LIST = 3;
export const PAGE_INTENT_EMPLOYEE = 4;
export const PAGE_INTENT_SAVE_COMPANY = 5;

export const DELETE_TYPE_HAS_OPENED = 1;
export const DELETE_TYPE_NONE_OPENED = 2;
export const DELETE_TYPE_SINGLE = 3;

export const TYPE_SAVED_TRUE = true;
export const TYPE_SAVED_FALSE = false;

export const TYPE_EMPLOYEE = true;