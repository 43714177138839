export default {
  /** label */
  sequence_filter_label: "Label",
  sequence_name_label_place: "Label",
  /** starred */
  sequence_filter_status_starred: "Starred",
  starredOnly: "Starred Only",
  starredFirst: "Starred First",

  /** name owner */
  sequence_filter_status_ownedby: "Owned by",
  sequence_name_owner_place: "Name Owner",

  /** status */
  sequence_filter_status: "Status",
  sequence_filter_status_active_inactive: "Active & Inactive",
  sequence_filter_status_active: "Active",
  sequence_filter_status_inactive: "Inactive",
  sequence_filter_status_archived: "Archived",

  /** delete message */
  popconfirm_delete_title: "Are you sure you want to delete this?",
  popconfirm_delete_yes: "Yes",
  popconfirm_delete_no: "No",

  /** create label */
  "sequence-new-label-title": "New Label",
  "sequence-new-label-desc": "Please enter a new label name:",
  "sequence-new-label-placeholder": "Enter a new label name",
  "sequence-new-label-button": "Create",

  /** edit label */
  "sequence-edit-label-title": "Rename Label",
  "sequence-edit-label-desc": "Please enter a label name:",
  "sequence-edit-label-placeholder": "Enter a label name",
  "sequence-edit-label-button": "Save",

  /** detail inbox */
  "sequence-detail-inbox-enter-email": "Please select emails.",

  /** detail inbox steps */
  "sequence-inbox-steps-version-label": "Versions",
  "sequence-inbox-steps-version-placeholder": "please select versions",
  "sequence-inbox-steps-step-label": "Steps",
  "sequence-inbox-steps-step-placeholder": "please select steps",
};
