<template>
  <div class="dashboard" >
    <NavBar />
    <keep-alive exclude="intent">
      <router-view />
    </keep-alive>
    <MaximumQueryPopup />
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import MaximumQueryPopup from './components/MaximumQueryPopup.vue';

export default {
  name: "Dashboard",
  components:{
    NavBar,
    MaximumQueryPopup
  },
  data(){
    return{
      isDashboard: true,
    }
  },
  methods:{

  }
}

</script>

<style scoped lang="scss">
.dashboard{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height:100vh;
}
</style>



