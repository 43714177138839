import request from '@/utils/request';


export function getCompanyList(data){
    return request({
        url:'/companies/search/name',
        method:'post',
        data
    })
}
export function getIndustry(){
    return request({
        url:'/verticals',
        method:'get',
    })
}
/*-------------------------------------phase I 暂时没有，性能提升 -----------------------------------------------*/
export function getSpeciality(){
    return request({
        url:'/specialities/search/keywords',
        method:'get',
    })
}
/*-------------------------------------companyGroup------------------------------------------------*/
export function getCompanyGroup(data){
    return request({
        url:'/saved/company_group',
        method:'post',
        data
    })
}
/*---------------------handle list-----------------------------------*/
export function addCompany(data){
    return request({
        url:'/saved/companies/add',
        method:'post',
        data
    })
}
/*---------------------删除item从group-----------------------------------*/
export function deleteCompany(data){
    return request({
        url:'/saved/companies/delete',
        method:'delete',
        data
    })
}
/*--------------------------------------------------------------------------------------*/
export function getMediaList(){
    return request({
        url:'/social_media',
        method:'get',
    })
}
export function getLocationList(){
    return request({
        method:'get',
        url:'/locations',
    })
}
export function getCompanySize(){
    return request({
        method:'get',
        url:'/company_sizes',
    })
}

export function getRevenue(){
    return request({
        method: 'get',
        url: '/revenue',
    })
}
//下面2个参数可传可不传，不传则返回所有数据

/*-----------获取技术列表----------------*/
export function getTechList(data){
    return request({
        method:'post',
        url:'/technologies/tags',
        data
    })
}
/*-----------获取技术分类列表----------------*/
export function getTechCategoryList(data){
    return request({
        method:'post',
        url:'/technologies/categories',
        data
    })
}
/*-----------获取技术详情（domain_id）----------------*/
export function getTechById(id){
    return request({
        method:'post',
        url:'/technologies/lookup',
        id
    })
}
/*-----综合搜索公司-------*/
export function searchCompanyList(data){
    return request({
        method:'post',
        url:'/companies/search',
        data
    })
}
/*-----公司详情页（domain_id）------*/
export function getCompanyDetail(data){
    return request({
        method:'post',
        url:'/companies/lookup',
        data
    })
}

/*-----根据groups返回domain ID------*/
export function getDomainIdByGroup(data){
    return request({
        method:'post',
        url:'/companies/select',
        data
    })
}
/*---------------export------------------*/
export function exportCompany(data, cb){
    return request({
        method:'post',
        url:'/companies/export',
        data,
        responseType: 'blob',
        onDownloadProgress(e) {
            const complete = e.loaded / e.total * 100;
            cb && cb(Math.round(complete));
        }
    })
}
/*---------------save search------------------*/
export function getSaveList(data){
    return request({
        method:'post',
        url:'/saved/saved_searches',
        data
    })
}
export function addSaveList(data){
    return request({
        method:'post',
        url:'/saved/saved_searches/create',
        data
    })
}
export function deleteSaveList(data){
    return request({
        method:'delete',
        url:'/saved/saved_searches/delete',
        data
    })
}
export function updateSaveList(data){
    return request({
        method:'post',
        url:'/saved/saved_searches/update',
        data
    })
}