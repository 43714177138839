import { getDepartments, getDepartmentFunction, getManagementLevels } from '@/api/people';
import { getCompanyList, getLocationList, getIndustry, getTechList, getTechCategoryList, getMediaList, getRevenue } from '@/api/company';

const state = {
  departments: localStorage.getItem('departments') ? JSON.parse(localStorage.getItem('departments')) : [],
  departmentFunctions: localStorage.getItem('departmentFunctions') ? JSON.parse(localStorage.getItem('departmentFunctions')) : [],//部门Function
  managementLevels: localStorage.getItem('managementLevels') ? JSON.parse(localStorage.getItem('managementLevels')) : [],//管理级别
  companyLists: [],
  locationList: localStorage.getItem('locationList') ? JSON.parse(localStorage.getItem('locationList')) : [],
  industryList: localStorage.getItem('IndustryList') ? JSON.parse(localStorage.getItem('IndustryList')) : [],
  techList: [],
  techCategoryList: JSON.parse(localStorage.getItem('techCategoryList') || '[]'),
  mediaList: localStorage.getItem('mediaList') ? JSON.parse(localStorage.getItem('mediaList')) : [],
  revenueList: [],
  maximumQueryLimitShow: false,
}

const mutations = {
  setDepartments(state, List) {
    localStorage.setItem('departments', JSON.stringify(List))
  },
  setDepartmentFunctions(state, List) {
    localStorage.setItem('departmentFunctions', JSON.stringify(List))
  },
  setManagementLevels(state, List) {
    localStorage.setItem('managementLevels', JSON.stringify(List))
  },
  setCompanyList(state, companyList) {
    state.companyLists = companyList
  },
  setLocationList(state, locationList) {
    localStorage.setItem('locationList', JSON.stringify(locationList))
  },
  setIndustryList(state, industryList) {
    localStorage.setItem('IndustryList', JSON.stringify(industryList))
  },
  setTechList(state, techList) {
    state.techList = techList
  },
  setTechCategoryList(state, techCategoryList) {
    techCategoryList?.length && localStorage.setItem('techCategoryList', JSON.stringify(techCategoryList))
  },
  setMediaList(state, mediaList) {
    localStorage.setItem('mediaList', JSON.stringify(mediaList))
  },
  setRevenueList(state, revenueList) {
    state.revenueList = revenueList;
  },
  setMaximumQueryLimitShow(state) {
    state.maximumQueryLimitShow = !state.maximumQueryLimitShow;
  }
}

const actions = {
  /*-------------人员部门----------------*/
  async getDepartments(context) {
    try {
      const result = await getDepartments();
      context.commit('setDepartments', result?.data)
      return result?.data
    } catch (err) {
      console.log(err)
      throw err
    }
  },
  /*-------------部门Function----------------*/
  async getDepartmentFunctions(context) {
    try {
      const result = await getDepartmentFunction()
      context.commit('setDepartmentFunctions', result?.data)
      return result?.data
    } catch (err) {
      console.log(err)
      throw err
    }
  },
  /*-------------人员管理级别----------------*/
  async getManagementLevels(context) {
    try {
      const result = await getManagementLevels()
      context.commit('setManagementLevels', result?.data)
      return result?.data
    } catch (err) {
      console.log(err)
      throw err
    }
  },
  /*---------------推荐公司列表----------------------*/
  async getCompanyList(context, val) {
    try {
      const CompanyList = await getCompanyList({ "company_name": val });
      context.commit('setCompanyList', CompanyList?.data);
      return CompanyList?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getLocationList(context) {
    try {
      const result = await getLocationList();
      context.commit('setLocationList', result?.data);
      return result?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getIndustry(context) {
    try {
      const IndustryList = await getIndustry();
      context.commit('setIndustryList', IndustryList?.data);
      return IndustryList?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getTechList(context, data) {
    try {
      const result = await getTechList(data);
      context.commit('setTechList', result?.data);
      return result?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getCategoryList(context, data) {
    try {
      const result = await getTechCategoryList(data);
      context.commit('setTechCategoryList', result?.data);
      return result?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getMediaList(context) {
    try {
      const result = await getMediaList();
      context.commit('setMediaList', result?.data);
      return result?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getRevenue(context) {
    try {
      const revenueList = await getRevenue();
      context.commit('setRevenueList', revenueList?.data);
      return revenueList?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
